import React from "react";

// Animations
import { Fade } from "react-awesome-reveal";

export const TransitionContainer = ({ children }) => (
	<Fade triggerOnce duration={500} delay={150}>
		{children}
	</Fade>
);
