import React from "react";
import styled from "styled-components";

const ContainerComponent = styled.div`
	position: relative;
	width: 100%;

	// margin: 100px auto 0 auto;
	margin: ${(props) => props.margin};
	padding: ${(props) => (props.padding ? props.padding : `0`)};

	@media (max-width: 768px) {
		margin: ${(props) => props.mobileMargin};

		padding: 0;
	}
`;

export const ContentContainer = ({
	children,
	id,
	margin,
	padding,
	marginOverride,
}) => (
	<ContainerComponent
		margin={marginOverride ? 0 : `100px auto 0 auto`}
		mobileMargin={marginOverride ? 0 : `60px auto 0 auto`}
		padding={padding}
		id={id}
	>
		{children}
	</ContainerComponent>
);
